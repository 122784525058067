import { useEffect, useState } from "react";
import { APIGet } from "../../helpers/api";
import { Nav } from "react-bootstrap";
import CustomBracket from "../../Component/CustomBracket";
import { useParams } from "react-router-dom";

function Brackets({Groups}) {
    const {id =0} = useParams()
    const [standings, setStandings] = useState([])
    const [selectedGroupId, setSelectedGroupId] = useState(0)
    const [finalMatches , setFinalmatches ] = useState(null)
    useEffect(()=>{
        if((selectedGroupId >0) ||  id >0){
            APIGet(process.env.REACT_APP_BASE_URL, `getStandingsByGameGroupId/${selectedGroupId || id}`).then(success => {
                setStandings(success.data)
            })
            APIGet(process.env.REACT_APP_BASE_URL, `get-Final-Matches/${selectedGroupId || id}`).then(success => {
                setFinalmatches(transformData(success.data))
            })
        }
    },[selectedGroupId,id])
    function transformData(data) {
        // Group matches by round and sort rounds by round_order
        const rounds = {};
        data.forEach(match => {
            const round = match.round.trim();
            if (!rounds[round]) {
                rounds[round] = [];
            }
            rounds[round].push(match);
        });
    
        const sortedRounds = Object.keys(rounds).sort((a, b) => {
            return rounds[a][0].round_order - rounds[b][0].round_order;
        });
    
        // Convert matches to the desired format
        const transformedData = [];
        let seedIdCounter = 1;
        sortedRounds.forEach(roundTitle => {
            const seeds = [];
            const matches = rounds[roundTitle].sort((a, b) => a.id - b.id);
            matches.forEach((match, index) => {
                seeds.push({
                    id: seedIdCounter,
                    matchid : match.id,
                    roundid:match.round_id,
                    roundname:match.round,
                    venue_name:match.venue_name,
                    venue_field:match.venue_field,
                    venue_field_timeslot:match.venue_field_timeslot,
                    match_id_be_decided:match.match_id_be_decided,
                    match_type:match.match_type,
                    winner:match.winner,
                    date: match.matchDate, // You can replace this with the actual date
                    teams: [
                        { id: match.Team1.id, name: match.Team1.team_name, score:match.score_one, team_logo : match.Team1.team_logo},
                        { id: match.Team2.id, name: match.Team2.team_name ,  score:match.score_two, team_logo : match.Team2.team_logo }
                    ]
                });
                seedIdCounter +=1;
            });
            transformedData.push({ title: roundTitle, round_id: rounds[roundTitle][0].round_id, seeds: seeds });
        });
    
        return transformedData;
    }

    return ( <> 
   <div className='d-flex justify-content-space-between     align-items-center w-100'>

{
    Groups?.length ?<>
    <Nav variant="pills" className="mb-5" defaultActiveKey="/home">
    {
        Groups?.map((game, gameIndex) => {
            if (!selectedGroupId) {
                setSelectedGroupId(prev => (prev ? prev : game.group_id))
            }
            return <Nav.Item>
                <Nav.Link onClick={() => { setSelectedGroupId(game.group_id) }} active={selectedGroupId == game.group_id} >  {game.group_name}</Nav.Link>
            </Nav.Item>
        })
    }
</Nav>
    </>:<></>
}

</div>
<CustomBracket   rounds={finalMatches}     standingsTeams ={standings}   /> 
    </> );
}

export default Brackets;