// services/APIPost.js

import axios from 'axios';

export const APIPost = async (baseUrl, route, data) => {
  try {
    const response = await axios.post(`${baseUrl}${route}`, data, {
      headers: {
        'access-key': 'giu@123',
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const APIGet = async (baseUrl, route) => {
  try {
    const response = await axios.get(`${baseUrl}${route}`, {
      headers: {
        'access-key': 'giu@123',
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Return response data instead of the entire response
  } catch (error) {
    throw error; // Rethrow the error for handling in the calling code
  }
};


export function generateImage(text, fontSize = 30, fontFamily = 'Arial') {
  const initials = (text?.split(' ').map(word => word.charAt(0).toUpperCase()).join(''))?.slice(0, 2);
  const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
          <!-- Background rectangle -->
          <rect width="100%" height="100%" fill="#edcdca"/>
          
          <!-- Text element -->
          <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle"
              font-size="${fontSize}" font-family="${fontFamily}" fill="white">
              ${initials}
          </text>
      </svg>
  `;

  // Encode SVG string to data URL
  const encodedData = window.btoa(unescape(encodeURIComponent(svgString)));
  const dataURL = `data:image/svg+xml;base64,${encodedData}`;
  
  return dataURL;
}


export function convertTo12HourFormat(time24) {
	// Split the input string into hours and minutes
	var timeParts = time24.split(":");
	var hours = parseInt(timeParts[0]);
	var minutes = parseInt(timeParts[1]);

	// Determine AM/PM
	var period = hours >= 12 ? "PM" : "AM";

	// Convert hours to 12-hour format
	hours = hours % 12;
	hours = hours ? hours : 12; // Handle midnight (00:00) as 12 AM

	// Format the time as HH:MM AM/PM
	var time12 = hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + period;

	return time12;
}