import React from 'react';
import { Image, Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FooterLogo } from '../../Img/img';
import './footer.css';
import { Nav } from 'react-bootstrap';

const Footer = ({ aboutRef, organizerRef, coachRef, playersRef, contactRef, privacyRef }) => {

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const scrollToSection = (ref) => {
        if (ref && ref.current) {
            window.scrollTo({
                top: ref.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };


    const handleNavLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    return (
        <>
            <section className="footer mt-5 pt-4 pb-4">
                <Container>
                    <Row>
                        <Col xxl="12" md="12" xs="12" className="text-center">
                            <div className="footer-logo text-center">
                                <Image src={FooterLogo} alt="Logo" />
                            </div>
                            <div className="footer-widget text-center">
                                <ul className="nav justify-content-center mt-3">
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link " href={"/#about"} onClick={() => scrollToSection(aboutRef)}>About</Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link" href={"/#organizer"} onClick={() => scrollToSection(organizerRef)}>Organizer </Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link " href={"/#coach"} onClick={() => scrollToSection(coachRef)}>Coach </Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link " href={"/#players"} onClick={() => scrollToSection(playersRef)}>Players</Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link " href={"/#contact"} onClick={() => scrollToSection(contactRef)}>Contact</Nav.Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-content mt-4">
                                <div className="content mt-2 ms-3">
                                    <div className="footer-content mb-2">
                                        <div className="icons mt-2">
                                            <NavLink to="https://www.facebook.com/GridironUniversity/" target="_blank"><i
                                                className="fa-brands fa-facebook"></i>
                                            </NavLink>
                                            <NavLink to="https://twitter.com/i/flow/login?redirect_after_login=%2Fgiubrotherhood" target="_blank">
                                                <i className="fa-brands fa-twitter"></i>
                                            </NavLink>
                                            <NavLink to="https://www.instagram.com/universitygridiron/?hl=en" target="_blank">
                                                <i className="fa-brands fa-instagram"></i>
                                            </NavLink>
                                            <NavLink to="https://www.youtube.com/channel/UCN1JgHYnD8D_IJfYQeyjWdA" target="_blank">
                                                <i className="fa-brands fa-youtube"></i>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="desclaimer text-align-center ">
                <Container>
                    <Row className="footer-row">
                        <Col xxl="6" md="12" xs="12">
                            <div className="footer-text">
                                <h2>© {(new Date()).getFullYear()} Gridiron University Inc. Copyright and All rights reserved.</h2>
                            </div>
                        </Col>
                        <Col xxl="4" md="12" xs="12"></Col>
                        <Col xxl="2" md="12" xs="12">
                            <div className="footer-text">
                                <NavLink to="/Privacy-Policy" onClick={handleNavLinkClick}>
                                    Privacy Policy
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Footer;