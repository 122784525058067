import React,{useRef, useEffect} from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Nav, Image, Navbar } from 'react-bootstrap';
import { HeaderLogo } from '../../Img/img';
import './header.css';

const Header = ({aboutRef, organizerRef, coachRef, playersRef, contactRef}) => {

    const handleScrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	  };

        const scrollToSection = (ref) => {
            if (ref && ref.current) {
                window.scrollTo({
                    top: ref.current.offsetTop,
                    behavior: 'smooth',
                });
            }
        };
      
    return (
        <>
            <header className="fixed-top">
                <div className="header-menu terms-policy-page">
                    <Navbar expand="lg" className="bg-body-tertiary">
                        <div className="container" style={{ position: 'relative' }}>
                            <div className="logo">
                                <NavLink className="navbar-brand navbar-brand-img" to="/" onClick={handleScrollToTop}>
                                    <Image src={HeaderLogo} alt="" />
                                </NavLink>
                            </div>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />

                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-auto nav-pills navbar_header_flex">
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link mx-2" href={"/#about"} onClick={() => scrollToSection(aboutRef)}>About</Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link mx-2" href={"/#organizer"} onClick={() => scrollToSection(organizerRef)}>Organizer </Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link mx-2" href={"/#coach"} onClick={() => scrollToSection(coachRef)}>Coach </Nav.Link>
                                    </li> 
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link mx-2" href={"/#players"} onClick={() => scrollToSection(playersRef)}>Players</Nav.Link>
                                    </li>
                                    <li className="nav-item">
                                        <Nav.Link className="nav-link mx-2" href={"/#contact"} onClick={() => scrollToSection(contactRef)}>Contact</Nav.Link>
                                    </li>
                                </Nav>
                                <Nav className="ms-auto d-lg-inline-flex icon mobile_buttons">
                                    <li className="nav-item mx-2 button_li">
                                        <NavLink className="nav-link " aria-current="page" to="/leagues-tournaments">
                                            <button className=" btn btn-primary games_btn">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M10 20C8.62 20 7.32148 19.737 6.10444 19.2111C4.88741 18.6852 3.82926 17.9715 2.93 17.07C2.02852 16.17 1.31481 15.1119 0.788889 13.8956C0.262963 12.6785 0 11.38 0 10C0 8.62 0.262963 7.32148 0.788889 6.10444C1.31556 4.88815 2.02926 3.83 2.93 2.93C3.83 2.02852 4.88815 1.31481 6.10444 0.788889C7.32148 0.262963 8.62 0 10 0C11.38 0 12.6785 0.262963 13.8956 0.788889C15.1119 1.31556 16.17 2.02926 17.07 2.93C17.9707 3.83 18.6841 4.88815 19.21 6.10444C19.7367 7.32148 20 8.62 20 10C20 11.38 19.737 12.6785 19.2111 13.8956C18.6844 15.1119 17.9707 16.17 17.07 17.07C16.17 17.9707 15.1119 18.6841 13.8956 19.21C12.6785 19.7367 11.38 20 10 20ZM15.1711 7.86333L17.0978 7.23556L17.6278 5.35C17.0352 4.39 16.287 3.57296 15.3833 2.89889C14.4781 2.22482 13.4685 1.73 12.3544 1.41444L10.5556 2.68333V4.62444L15.1711 7.86333ZM4.82889 7.86333L9.44444 4.62444V2.68333L7.64444 1.41444C6.53111 1.72926 5.52926 2.22407 4.63889 2.89889C3.74852 3.57296 3.00704 4.39 2.41444 5.35L2.94444 7.23556L4.82889 7.86333ZM3.36222 15.9489L5.11111 15.7944L6.2 13.9956L4.54667 8.99111L2.56444 8.30778L1.11111 9.35444C1.11111 10.6581 1.27037 11.8304 1.58889 12.8711C1.90889 13.9111 2.49926 14.937 3.36222 15.9489ZM10 18.8889C10.4815 18.8889 10.9678 18.8481 11.4589 18.7667C11.9507 18.6852 12.4619 18.5637 12.9922 18.4022L13.94 16.35L12.9622 14.7433H7.03778L6.10111 16.35L7.05 18.4022C7.53741 18.5644 8.03 18.6859 8.52778 18.7667C9.02778 18.8481 9.51852 18.8889 10 18.8889ZM7.28667 13.6333H12.7567L14.3978 8.67556L10 5.58556L5.61556 8.67445L7.28667 13.6322V13.6333ZM16.6367 15.9489C17.4996 14.937 18.0907 13.9111 18.41 12.8711C18.7293 11.8304 18.8889 10.6581 18.8889 9.35444L17.4778 8.36333L15.4533 8.94889L13.7989 13.9956L14.8889 15.7956L16.6367 15.9489Z" fill="white"/>
                                                    </svg>
                                                </span>
                                                All Games
                                            </button>
                                        </NavLink>
                                        <NavLink className="nav-link " aria-current="page" to="https://app.giuteamconnect.com/" target="_blank">
                                            <button className="btn btn-primary signin-btn">
                                                <i className="fa-solid fa-arrow-right-from-bracket  mx-2"></i>
                                                Sign In 
                                            </button>
                                        </NavLink>
                                    </li>
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                </div>
            </header>
        </>
    )
}

export default Header;