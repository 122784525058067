import React from 'react';
import {   PlayStore, AppleStore } from '../../../Img/img';
import {Image, Col, Row, Container} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

const DownloadApp = () => {
  return (
    <>
        <section className="yourself">
				<Container>
					<Row>
						<Col sm="12" className="mt-5 pb-5">
							<div className="banner-text text-center mt-4 pt-5 pb-5">
								<h1>DOWNLOAD THE TEAMCONNECT APP</h1>
								<div className="download-app mt-5">
									<NavLink to="https://play.google.com/store/apps/details?id=com.giufootball.teamconnect"
										target="_blank"> <Image src={PlayStore} /></NavLink>
									<NavLink to="https://apps.apple.com/us/app/giu-team-connect/id6452012682" target="_blank">
										<Image src={AppleStore} /></NavLink>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
    </>
  )
}

export default DownloadApp;