import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { Trophy, UserPic, Football } from '../../../Img/img';
import { APIPost } from '../../../helpers/api';
import moment from 'moment';
import '../../../App.css';

export const FirstCard = ({ data, margin = 1, Organizer_id }) => {
    const navigate = useNavigate();

    const handleItemClick = async (itemId, status,season_id) => {
        try {
            if (['ongoing', 'completed', 'enroll', 'current'].includes(status)) {
                const baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await APIPost(baseUrl, `get-games-Managed/${itemId}`, {
                    filter: {
                        keyword: '',
                        Title: '',
                        Type: '',
                        Start_Date: '',
                        End_Date: ''
                    },
                    Organizer_id: Organizer_id,
                    Page_Number: 1,
                    Page: 'O'
                });

                const { id, success, itemId } = response.data;
                if (success) {
                    navigate(`/gameType/gameName/${id}`);
                } else {
                    console.error('Failed to fetch details');
                }
            } else {
                console.log('Not making an API call for this status:', status);
                // Optionally, you can handle other cases here
            }
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            {data?.map((giu, i) => {
                //   console.log(giu,i,"giu 1")
                return <div className={margin == 0 ? `league-history swiper-slide m-0` : `league-history swiper-slide`}>
                    <div key={giu.id} className="card" onClick={() => handleItemClick(giu.id, giu.game_status, giu.season_id)}>

                     
                            <div className="football_icon">
                                <img src={giu.icon} />
                            </div>
                        
                        <NavLink key={giu.id} className="link-text" to={`/${giu.gametype}/${(giu.game_title).replaceAll(' ', '_')}/${btoa(giu.id +" "+ giu.season_id)}`} onClick={handleScrollToTop}>
                            {giu.game_status == "ongoing" ? <>
                                <div class="ongoing-legue">
                                    <div className="nfl-win">
                                        <p>
                                            <span className="game_title_org">{giu.game_title}</span>
                                            <span className="sports_game_f"> ({giu.gametype})</span>
                                        </p>
                                        <div className="completed ongoing-active">
                                            {giu.game_status}
                                        </div>
                                    </div>
                                    <hr className="mt-0" />
                                    <div className="">
                                        <div className="win t-win mt-0 ongoing_startDate">
                                            <h6>Started On</h6>
                                            <h4>{moment(giu.start_date).format('Do MMM')}</h4>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div className="org-pro">
                                    <div className="dates_org_pro">
                                        <span>
                                            <strong>START : {moment(giu.start_date).format('MM/DD/YY')}</strong>
                                        </span>
                                        <hr className="line-start-end" />
                                        <span className="orange">
                                            <strong>Complete : {moment(giu.end_date).format('MM/DD/YY')}</strong>
                                        </span>
                                    </div>
                                    <hr className="line_between_image" />
                                    <div className="org-pro-detail">
                                        <img src={giu.profile_pic ? giu.profile_pic : UserPic} alt="UserPic" />
                                        <div className="ms-2">
                                            <strong>{giu.firstname}
                                                <p>(Organizer)</p>
                                            </strong></div>
                                    </div>
                                </div>
                            </> : <>{giu.game_status == "completed" ?
                                <>
                                    <div className="nfl-win">
                                        <p><span className="game_title_org">{giu.game_title}</span>
                                            <span className="sports_game_f"> ({giu.gametype})</span>
                                        </p>
                                        <div className="completed">
                                            {giu.game_status}
                                        </div>
                                    </div>
                                    <hr className="mt-0" />
                                    <div className="win t-win">
                                        <div className="winner_detail_image">
                                            <Image className="img-fluid" src={Trophy} />
                                            <div className="completed_winner_detail">
                                                <h4>TEAM 1</h4>
                                                <p>Winner</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="org-pro">
                                        <div className="dates_org_pro">
                                            <span>
                                                <strong>START : {new Date(giu.start_date).toLocaleDateString()}</strong>
                                            </span>
                                            <hr className="line-start-end" />
                                            <span>
                                                <strong>Complete : {new Date(giu.end_date).toLocaleDateString()}</strong>
                                            </span>
                                        </div>
                                        <hr className="line_between_image" />
                                        <div className="org-pro-detail">
                                            <img src={giu.profile_pic ? giu.profile_pic : UserPic} alt="UserPic" />
                                            <div className="ms-2">
                                                <strong>{giu.firstname}
                                                    <p>(Organizer)</p>
                                                </strong></div>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    {
                                        giu.game_status == "upcoming" || giu.game_status == "enroll" ?
                                            <>
                                                <div className="nfl-win upcoming-box">
                                                    <div><p><span className="game_title_org">{giu.game_title}</span>
                                                    </p>
                                                        <b> <p className="mt-0">{giu?.season_name}</p></b>
                                                        <span className="sports_game_f"> ({giu.gametype})</span>
                                                    </div>

                                                    <div className="completed ongoing-active">
                                                        {giu.game_status}
                                                    </div>
                                                </div>
                                                <hr className="mt-0" />
                                                <div className="upcoming-le">
                                                    <h6>Starting on</h6>
                                                    <div className="win t-win ">

                                                        <div>
                                                            <h6 className="game_title_org"> START : {moment(giu.start_date).format('ll')}</h6>
                                                            {/* <span className="sports_game_f"> ({giu.gametype})</span> */}
                                                        </div>

                                                    </div>
                                                </div>

                                                <hr />
                                                <div className="org-pro">
                                                    <div className="dates_org_pro">
                                                        <span className="green">
                                                            <strong>START : {new Date(giu.start_date).toLocaleDateString()}</strong>
                                                        </span>
                                                        <hr className="line-start-end" />
                                                        <span className="orange">
                                                            <strong>Complete : {new Date(giu.end_date).toLocaleDateString()}</strong>
                                                        </span>
                                                    </div>
                                                    <hr className="line_between_image" />

                                                    <div className="org-pro-detail">
                                                        <img src={giu.profile_pic ? giu.profile_pic : UserPic} alt="UserPic" />
                                                        <div className="ms-2">
                                                            <strong>{giu.firstname}
                                                                <p>(Organizer)</p>
                                                            </strong></div>
                                                    </div>
                                                </div>
                                            </>
                                            : <>
                                                <div class="onroll-legue">
                                                    <div className="nfl-win">
                                                        <p>
                                                            <span className="game_title_org">{giu.game_title}</span>
                                                            <span className="sports_game_f">{giu.sports_name === "Football" ? Football : "Basketball"} ({giu.gametype})</span>
                                                        </p>
                                                        <div className="completed ongoing-active">
                                                            {giu.game_status}
                                                        </div>
                                                    </div>
                                                    <hr className="mt-0" />
                                                    <div className="">
                                                        <div className="win t-win mt-0 ongoing_startDate">
                                                            <h6>Started On</h6>
                                                            <h4>{moment(giu.start_date).format('MMM Do')}</h4>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                                <div className="org-pro">
                                                    <div className="dates_org_pro">
                                                        <span className="green">
                                                            <strong>START : {new Date(giu.start_date).toLocaleDateString()}</strong>
                                                        </span>
                                                        <hr className="line-start-end" />
                                                        <span className="orange">
                                                            <strong>Complete : {new Date(giu.end_date).toLocaleDateString()}</strong>
                                                        </span>
                                                    </div>
                                                    <hr className="line_between_image" />
                                                    <div className="org-pro-detail">
                                                        <img src={giu.profile_pic ? giu.profile_pic : UserPic} alt="UserPic" />
                                                        <div className="ms-2">
                                                            <strong>{giu.firstname}
                                                                <p>(Organizer)</p>
                                                            </strong></div>
                                                    </div>
                                                </div>
                                            </>
                                    }</>}</>
                            }
                        </NavLink>
                    </div>
                </div>
            })}
        </>
    )
}

